<template>
<div class="report report-labarugi">
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title row text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>Toko</span>
                            <el-select v-model="filter.store_id" placeholder="Pilih Toko" @clear="onFilter()" clearable @change="onFilter">
                                <el-option v-for="(o, i) in listStore" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Brand</span>
                            <el-select v-model="filter.brand_id" @change="onFilter()" @clear="onFilter()" clearable placeholder="Brand" style="width: 200px;">
                                <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Kategori</span>
                            <el-select v-model="filter.category_id" @change="onFilter()" @clear="onFilter()" clearable placeholder="Kategori" style="width: 200px;">
                                <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Pembayaran</span>
                            <el-select v-model="filter.payment_id" @change="onFilter()" @clear="onFilter()" clearable placeholder="Pembayaran" style="width: 200px;">
                                <el-option v-for="(o, i) in listPaymentMethod" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Tanggal</span>
                            <el-date-picker
                              class="form-control"
                              v-model="filter.date"
                              type="daterange"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              :shortcuts="dateShortcuts"
                              :size="'large'"
                              :clearable="false"
                              @change="onFilter()"
                              format="DD/MM/YYYY"
                              value-format="YYYY-MM-DD"
                            />
                        </div>
                        <div class="filter-item">
                          <span>SEARCH</span>
                          <el-input v-model="filter.search" placeholder="Search" clearable @change="onFilter()" />
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                    <div class="my-3 fs-6 p-0 text-gray-600 text-uppercase" v-if="filterActive" v-show="false">
                        <span>Hasil filter</span>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="row g-2 justify-content-end" data-kt-table-toolbar="base">
                        <div class="col-auto">
                            <router-link to="/laporan/laba_rugi/summary" class="btn btn-bg-light btn-color-dark btn-active-secondary"><i class="fas fa-external-link-alt me-2"></i>Ringkasan Semua Waktu</router-link>
                        </div>
                    <div class="col-auto">
                        <button
                          class="btn btn-primary me-3"
                          :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                          @click="exportLabaRugi"
                        >
                          <span class="indicator-label">
                              <span class="svg-icon svg-icon-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                      <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                      <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                  </svg>
                              </span>
                            Export
                          </span>

                          <span class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th style="min-width: 150px;">Toko</th>
                            <th class="w-150px">Tanggal</th>
                            <th>Kode Transaksi</th>
                            <th>Pembayaran</th>
                            <th>Brand</th>
                            <th>Kategori</th>
                            <th>Variasi</th>
                            <th class="text-end">Harga</th>
                            <th class="text-end">Qty</th>
                            <th class="text-end">Subtotal</th>
                            <th class="text-end">Diskon</th>
                            <th class="text-end">Harga Jual</th>
                            <th class="text-end">Sharing Profit</th>
                            <th class="text-end">HPP Item</th>
                            <th class="text-end">HPP Desain</th>
                            <th class="text-end">NETT</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ item.index }}</td>
                            <td>{{ item.store }}</td>
                            <td class="w-100px">{{ item.tanggal }}</td>
                            <td>{{ item.kode_inv }}</td>
                            <td>
                                <span>{{ item.payment_method }}</span>
                                <span v-if="item.qris"> - {{ item.qris }}</span>
                                <span v-if="item.bank"> - {{ item.bank }}</span>
                            </td>
                            <td>{{ item.brand_name }}</td>
                            <td>{{ item.category }}</td>
                            <td>{{ item.color + ' - ' + item.size }}</td>
                            <td class="text-end">{{ formatIDR(item.price) }}</td>
                            <td class="text-end">{{ item.qty }}</td>
                            <td class="text-end">{{ formatIDR(item.gross) }}</td>
                            <td class="text-end">{{ formatIDR(item.discount) }}</td>
                            <td class="text-end">{{ formatIDR(item.netto) }}</td>
                            <td class="text-end">{{ formatIDR(item.sharing_profit) }}</td>
                            <td class="text-end">{{ formatIDR(item.hpp_item) }}</td>
                            <td class="text-end">{{ formatIDR(item.hpp_desain) }}</td>
                            <td class="text-end">{{ formatIDR(item.pnl) }}</td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center mt-7" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>
    </div>

    <LoadingSummary v-if="loadingSummary" />

    <div v-else>
        <div class="summary">
            <div class="summary-list-fixed row m-0">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                    <div class="d-flex text-gray-500">
                        <span class="text-uppercase">Total Harga Tag</span>
                        <span class="ms-auto">{{ formatIDR(summary.total_gross, { prefix: 'Rp'}) }}</span>
                    </div>
                    <div class="d-flex text-gray-500">
                        <span class="text-uppercase">Total Diskon</span>
                        <span class="ms-auto">{{ formatIDR(parseInt(summary.total_discount), { prefix: 'Rp' }) }}</span>
                    </div>
                    <div class="d-flex">
                        <span class="text-uppercase">Total Harga Jual</span>
                        <span class="ms-auto">{{ formatIDR(summary.total_nett, { prefix: 'Rp'}) }}</span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                    <div class="d-flex text-gray-500">
                        <span class="text-uppercase">Total HPP Item</span>
                        <span class="ms-auto">{{ formatIDR(summary.total_hpp_item, { prefix: 'Rp'}) }}</span>
                    </div>
                    <div class="d-flex text-gray-500">
                        <span class="text-uppercase">Total HPP Desain</span>
                        <span class="ms-auto ">{{ formatIDR(summary.total_hpp_desain, { prefix: 'Rp' }) }}</span>
                    </div>
                    <div class="d-flex">
                        <span class="text-uppercase">Total HPP</span>
                        <span class="ms-auto">{{ formatIDR(summary.total_hpp_item + summary.total_hpp_desain, { prefix: 'Rp' }) }}</span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                    <div class="d-flex">
                        <span class="text-uppercase">Total Payment Konsi</span>
                        <span class="ms-auto">{{ formatIDR(summary.total_payment_konsi, { prefix: 'Rp'}) }}</span>
                    </div>
                    <div class="d-flex">
                        <span class="text-uppercase">Total PNL Konsi</span>
                        <span class="ms-auto">{{ formatIDR(summary.total_profit_konsi, { prefix: 'Rp' }) }}</span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                    <div class="d-flex">
                        <span class="text-uppercase">Total Qty</span>
                        <span class="ms-auto">{{ summary.total_qty }} PCS</span>
                    </div>
                    <div class="d-flex">
                        <span class="text-uppercase">Total PNL</span>
                        <span class="ms-auto">{{ formatIDR(summary.total_profit, {prefix: 'Rp'}) }}</span>
                    </div>
                    <div class="d-flex">
                        <span class="text-uppercase">ROE</span>
                        <span class="ms-auto">{{ summary.roe.toFixed(2) }}%</span>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_3">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_3_header_3">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_3_body_3"
                aria-expanded="true"
                aria-controls="kt_accordion_3_body_3"
              >
                Toko
              </button>
            </h2>
            <div
              id="kt_accordion_3_body_3"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_3_header_3"
              data-bs-parent="#kt_accordion_3"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.store" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.store_name }}</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                </div>
                                <div class="py-2">
                                    <ul class="ps-3 mt-1 text-gray-600 fs-7">
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total Harga Jual</div>
                                                <div>{{ formatIDR(o.total, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total HPP</div>
                                                <div>{{ formatIDR(o.total_hpp_item + o.total_hpp_desain, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li v-if="o.total_beban_shopee">
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total Beban Shopee ({{ o.beban_shopee }})</div>
                                                <div>{{ formatIDR(o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total PNL</div>
                                                <div>{{ formatIDR(o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">ROE</div>
                                                <div>{{ (((o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee) / o.total) * 100).toFixed(2) }}%</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_1">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_1_header_1">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_1"
                aria-expanded="true"
                aria-controls="kt_accordion_1_body_1"
              >
                Brand
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_1"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_1_header_1"
              data-bs-parent="#kt_accordion_1"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.brand" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.brand_name }}</span>
                                    <span class="ms-auto">{{ o.qty }} PCS</span>
                                </div>
                                <div class="py-2">
                                    <ul class="ps-3 mt-1 text-gray-600 fs-7">
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total Harga Jual</div>
                                                <div>{{ formatIDR(o.total, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total HPP</div>
                                                <div>{{ formatIDR(o.total_hpp_item + o.total_hpp_desain, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total PNL</div>
                                                <div>{{ formatIDR(o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">ROE</div>
                                                <div>{{ (((o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee) / o.total) * 100).toFixed(2) }}%</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_2">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_2_header_2">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_2_body_2"
                aria-expanded="true"
                aria-controls="kt_accordion_2_body_2"
              >
                Kategori
              </button>
            </h2>
            <div
              id="kt_accordion_2_body_2"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_2_header_2"
              data-bs-parent="#kt_accordion_2"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.category" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.category }}</span>
                                    <span class="ms-auto">{{ o.qty }} PCS</span>
                                </div>
                                <div class="py-2">
                                    <ul class="ps-3 mt-1 text-gray-600 fs-7">
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total Harga Jual</div>
                                                <div>{{ formatIDR(o.total, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total HPP</div>
                                                <div>{{ formatIDR(o.total_hpp_item + o.total_hpp_desain, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">Total PNL</div>
                                                <div>{{ formatIDR(o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div class="text-uppercase">ROE</div>
                                                <div>{{ (((o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee) / o.total) * 100).toFixed(2) }}%</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->
    
    </div>
</div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileDownload from 'js-file-download';
import Axios from 'axios'
import moment from 'moment'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import LoadingSummary from "@/components/LoadingSummary.vue"

export default defineComponent({
    components: { 
        //Field,
        //Form,
        //ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
        LoadingSummary,
    },
    setup() {
        const store = useStore()

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        moment.locale('id');

        const loadingSubmit = ref(false)
        const loadingTable = ref(false)
        const loadingSummary = ref(false)
        const tableRef = ref(null)

        const modal = ref(false)

        const filterActive = ref(false)
        const filter = reactive({
            store_id: null,
            date: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            brand_id: null,
            category_id: null,
            search: null,
        })

        const tableData = reactive([]);
        const totalRecordCount = ref(0);
        
        const summary = ref({
          total_item: 0,
          total_qty: 0,
          total_gross: 0,
          total_beban_shopee: 0,
          total_sharing_profit: 0,
          total_hpp_item: 0,
          total_hpp_desain: 0,
          total_nett: 0,
          total_profit: 0,
          total_payment_konsi: 0,
          total_profit_konsi: 0,
          roe: 0,
          store: 0,
          tipe_produk: [],
          brand: [],
          category: [],
          artikel: [],
          color: [],
          size: [],
          payment: [],
        })

        // FILTER
        const listStore = computed(() => store.getters.getListStore)
        const listBrand = computed(() => store.getters.getListBrand)
        const listCategory = computed(() => store.getters.getListCategory)
        const listPaymentMethod = computed(() => store.getters.getListPaymentMethod)

        const dateShortcuts = [
          {
            text: 'Last week',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            },
          },
          {
            text: 'Last month',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            },
          },
          {
            text: 'Last 3 months',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            },
          },
        ]
        
        const onFilter = async () => {
            filterActive.value = false

            if(!loadingTable.value) {
                await getData()
            }

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {
            Object.keys(filter).map(k => {
                if(k != 'date') {
                    filter[k] = null
                }
            })

            filterActive.value = false

            if(!loadingTable.value) {
                await getData()
            }
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

         const handlePageChange = async(page) => {
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position
            pagination.value.page = page
            await getData()
        }

        const exportLabaRugi = async() => {
          try {
          loadingSubmit.value = true

          if (!filter.date) {
            throw new Error("Silakan pilih tanggal")
          }

          if (!filter.store_id) {
            throw new Error("Silakan pilih toko")
          }

          let getLink = ''
          let getFileName = ''

          let start_date = filter.date ? filter.date[0] : null;
          let end_date = filter.date ? filter.date[1] : null;

          await ApiService.post('export_excel_laba_rugi', {
            start_date: start_date,
            end_date: end_date,
            store_id: filter.store_id,
            brand_id: filter.brand_id,
            payment_id: filter.payment_id,
          })
            .then(({ data }) => {
              if (data.error) {
                throw(data.messages)
              }

              getLink = data.data.link
              getFileName = data.data.fileName

              window.open(getLink, '_blank').focus();

              modal.value = false

              Swal.fire({
                title: 'Berhasil didownload',
                icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 2000,
              })
            })
            .catch((error) => {
              Swal.fire({
                title: error,
                icon: "error",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 2000,
              })
            });

          } catch(e) {
            console.log(e)
            Swal.fire({
              title: e.message,
              icon: "error",
              buttonsStyling: false,
              showConfirmButton: false,
              timer: 2000,
            })
          }

          loadingSubmit.value = false
        }

        const getSummary = async () => {
            try {
                loadingSummary.value = true

                let store_id = filter.store_id ? '&store_id=' + filter.store_id : '';
                let start_date = filter.date ? '&start_date=' + moment(filter.date[0]).format('YYYY-MM-DD') : '';
                let end_date = filter.date ? '&end_date=' + moment(filter.date[1]).format('YYYY-MM-DD') : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let payment_method = filter.payment_id ? '&payment_method=' + filter.payment_id : '';
                let search = filter.search ? '&search=' + filter.search : '';
                
                await ApiService.get("summary_laba_rugi?" + store_id + brand + category + payment_method + start_date + end_date + search)
                .then(async({ data }) => {
                    summary.value.total_qty = data.data.total_qty || 0
                    summary.value.total_gross = data.data.total_gross
                    summary.value.total_discount = data.data.total_discount
                    summary.value.total_ongkir = data.data.total_ongkir
                    summary.value.total_beban_shopee = data.data.total_beban_shopee
                    summary.value.total_nett = data.data.total_nett
                    summary.value.sharing_profit = data.data.sharing_profit
                    summary.value.total_profit = data.data.total_profit
                    summary.value.total_payment_konsi = data.data.total_payment_konsi
                    summary.value.total_profit_konsi = data.data.total_profit_konsi
                    summary.value.roe = data.data.total_nett > 0 ? (data.data.total_profit / data.data.total_nett) * 100 : 0;
                    summary.value.total_hpp_item = data.data.total_hpp_item
                    summary.value.total_hpp_desain = data.data.total_hpp_desain
                    summary.value.brand = data.data.brand
                    summary.value.category = data.data.category
                    summary.value.store = data.data.store
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

            } catch(e) {
                console.log(e)
            }


            loadingSummary.value = false
        }

        const getData = async() => {
            loadingTable.value = true
            loadingSummary.value = true
            
            try {
                await tableData.splice(0)
                await Object.keys(summary.value).map(k => summary[k] = null)
                
                let page = pagination.value.page ? '?page=' + pagination.value.page : '?page=1';
                let store_id = filter.store_id ? '&store_id=' + filter.store_id : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let payment_method = filter.payment_id ? '&payment_method=' + filter.payment_id : '';
                let start_date = filter.date ? '&start_date=' + moment(filter.date[0]).format('YYYY-MM-DD') : '';
                let end_date = filter.date ? '&end_date=' + moment(filter.date[1]).format('YYYY-MM-DD') : '';
                let search = filter.search ? '&search=' + filter.search : '';
                
                await ApiService.get("report_laba_rugi" + page + brand + category + payment_method + start_date + end_date + store_id + search)
                .then(async({ data }) => {
                    pagination.value = await data.data.pagination
                    await Object.assign(tableData, data.data.data)
                    getSummary()
                })
                .catch(({ response }) => {
                    throw new Error('Terjadi Error')
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
            
            loadingTable.value = false
        }

        function blobToFile(theBlob, fileName){
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        }

        onBeforeMount(() => {
            setCurrentPageBreadcrumbs("Laporan Laba Rugi", ['Laporan Laba Rugi']);

            store.dispatch(Actions.LIST_STORE);
            store.dispatch(Actions.LIST_BRAND);
            store.dispatch(Actions.LIST_CATEGORY);
            store.dispatch(Actions.LIST_PAYMENT_METHOD);

            getData()
        })

        return {
            moment,
            loadingSubmit, loadingTable, loadingSummary, tableRef, img_url_mockup, img_url_desain,
            onFilter, resetFilter, filter, filterActive, modal,
            listStore, listBrand, listPaymentMethod, listCategory,
            tableData, totalRecordCount, summary,
            handlePageChange, pagination,
            formatIDR, exportLabaRugi,
        }
    }
})
</script>